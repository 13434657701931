import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import useReCaptcha from '../../../utils/useReCaptcha';
import validateMobile from '../../../utils/validateMobile';
import Button from '../../Button';
import { useAccountState } from '../../context/account-context';
import { useAlert } from '../../context/alert-context';
import { TextField } from '../../Form';
import OnboardingLayout from '../OnboardingLayout';

const MobileLanding = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('mobileNumber', validateMobile);
  const Router = useRouter();
  const [, alertDispatch] = useAlert();
  const [formDisabled, setFormDisabled] = useState(false);
  const executeReCaptcha = useReCaptcha();
  const { isLoggedIn, logout } = useAccountState();

  const { mobileNumber, return_url: returnUrl } = Router.query;
  if (returnUrl) {
    const [, partnerUrl] = Router.asPath.split('?return_url=');
    Router.query = { partnerUrl, ...Router.query };

    if (isLoggedIn) {
      const redirect = `login?return_url=${partnerUrl}`;
      logout({ redirect });
    }
  }

  async function onSubmit(values) {
    delete Router.query.logout;

    try {
      // Call grecaptcha
      const res = await executeReCaptcha('MobileLanding', values.mobileNumber);
      if (res && res.code === '0000' && res.response.success) {
        setFormDisabled(true);
        Router.push({
          pathname: '/onboarding',
          query: {
            ...Router.query,
            mobileNumber: values.mobileNumber,
            flow: 'default',
          },
        });
      }
    } catch (err) {
      alertDispatch({
        type: 'SET_ALERT',
        value: 'Something went wrong. Please try again later.',
      });
    }
  }

  return (
    <OnboardingLayout>
      <header className="onboard-header text-center">
        <h1 className="h2">Hello and welcome to Link Rewards Programme!</h1>
        <p>Please enter your mobile number to continue.</p>
      </header>
      <form
        className="gtm-onboarding"
        id="mobile-no"
        onSubmit={handleSubmit(onSubmit)}
        css={`
          margin: 0 auto;
        `}
      >
        <fieldset disabled={formDisabled}>
          <TextField
            defaultValue={mobileNumber}
            label="Your mobile number"
            errors={errors}
            type="text"
            role="mobile-input"
            name="mobileNumber"
            {...rest}
            innerRef={ref}
          />

          <Button block type="submit">
            Next
          </Button>
        </fieldset>
      </form>
    </OnboardingLayout>
  );
};

export default MobileLanding;
