import styled, { css } from 'styled-components';

import { theme } from '../GlobalStyles';

import Input from './Input';
import Label from './Label';

function checkDateValue(dateValue, max) {
  const isZeroValue = dateValue.charAt(0) === '0' && dateValue !== '00';

  if (isZeroValue) {
    return dateValue;
  }

  let newDateValue = parseInt(dateValue, 10);
  if (isNaN(newDateValue) || newDateValue <= 0 || newDateValue > max) {
    newDateValue = 1;
  }

  return newDateValue > parseInt(max.toString().charAt(0), 10) &&
    newDateValue.toString().length === 1
    ? `0${newDateValue}`
    : newDateValue.toString();
}

function autoslash(e) {
  e.target.type = 'text';
  let input = e.target.value;
  if (/\D\/$/.test(input)) {
    input = input.substr(0, input.length - 3);
  }
  const values = input.split('/').map((v) => v.replace(/\D/g, ''));
  if (values[0]) {
    values[0] = checkDateValue(values[0], 31);
  }
  if (values[1]) {
    values[1] = checkDateValue(values[1], 12);
  }
  const output = values.map((v, i) => (v.length === 2 && i < 2 ? `${v} / ` : v));
  e.target.value = output.join('').substr(0, 14);
}

const StyledTextField = styled.div`
  display: block;
  margin-bottom: 2.375rem;

  .error {
    color: #e22062;
    font-size: 0.875rem;
    ${theme.mediaQueries.above.small} {
      position: absolute;
    }
  }
`;

const TextField = (props) => {
  const { innerRef, dob, label, labelButton, name, errors, disabled, children, testid, ...other } =
    props;
  const hasError = errors[name];

  return (
    <StyledTextField data-testid="text-field-component">
      <label>
        <Label disabled={disabled}>
          {label}
          {labelButton}
        </Label>
        <Input
          data-testid={`text-field-input${testid ? testid : ''}`}
          css={
            dob
              ? css`
                  word-spacing: -0.1875em;
                `
              : null
          }
          error={hasError}
          name={name}
          {...other}
          disabled={disabled}
          ref={innerRef}
          onInput={dob ? autoslash : null}
        />
      </label>
      {hasError && <div className="error">{hasError.message}</div>}
      {children}
    </StyledTextField>
  );
};

export default TextField;
