import { parseCookies } from 'nookies';

const LINK_TRAVEL_DEALS = 'linktraveldeals.sg';
const BUSONLINETICKET = 'busonlineticket.com';

const WHITE_LISTED_DOMAINS = [
  'stamfordcs.com.sg',
  'shop.stamfordcs.com.sg',
  'plus.com.sg',
  'link.sg',
  'vpost.com',
  'sg.vpost.com',
  'fairprice.com.sg',
  BUSONLINETICKET,
  LINK_TRAVEL_DEALS,
];

const ONLINE_MALLS_DOMAINS = ['tagserve.asia', 'tagserve.sg', 'accesstra.de', 'atsg.me'];

const doRedirect = (domain, returnUrl, accesstoken) => {
  const modifiedURL = new URL(returnUrl);

  if (ONLINE_MALLS_DOMAINS.includes(domain)) {
    modifiedURL.searchParams.set('SUBID', accesstoken);
    window.location = modifiedURL.href;
    return;
  }

  if (WHITE_LISTED_DOMAINS.includes(domain)) {
    if (![LINK_TRAVEL_DEALS, BUSONLINETICKET].includes(domain)) {
      modifiedURL.searchParams.set('accessToken', accesstoken);
    }

    if (domain === BUSONLINETICKET) {
      modifiedURL.searchParams.set('vc', accesstoken);
    }
    window.location = modifiedURL.href;
    return;
  }
  window.location = '/profile';
};

export default function redirectToPartner(returnUrl, loggedIn = true) {
  const { accesstoken } = parseCookies();

  const matches = returnUrl.match(/^https?:\/\/(?:www\.)?([^/?#]+)(?:[/?#]|$)/i);
  const domain = matches && matches[1];

  if (ONLINE_MALLS_DOMAINS.includes(domain) && !loggedIn) {
    return returnUrl;
  }

  if (accesstoken && loggedIn) {
    doRedirect(domain, returnUrl, accesstoken);
  }

  return false;
}
