import SeoHead from '../../SeoHead';
import OnboardingLayout from '../OnboardingLayout';

const MobileLandingRegister = () => {
  return (
    <OnboardingLayout>
      <SeoHead
        title="Register your card | Link"
        description="Register your Link card online to check your Linkpoints balance, membership and discover exclusive deals on the go."
      />
      <header className="onboard-header">
        <h1 className="h2" data-testid="register-card-component" style={{ color: 'red' }}>
          Starting 14 Feb 2025, the 300 Linkpoints incentive for new card registrations will no
          longer be available.
        </h1>
        <p>
          To register your card starting with <strong>8105 0000</strong>, you may do so by:
        </p>
        <p>1. Contacting our Customer Service hotline at 6380 5858 or </p>
        <p>
          2. Submit a request on{' '}
          <a href="https://support.link.sg/hc/en-us/requests/new" target="_blank">
            https://support.link.sg/hc/en-us/requests/new
          </a>{' '}
          by selecting ‘Link Rewards Card Registration’ option under ‘Card Matters’ section.
        </p>
        <p>
          For FairPrice members only, enjoy 4% savings. <sup>*</sup>
          <a
            href="https://image.marketing.link.sg/lib/fe3a15717564047b711372/m/18/51862c80-3267-41b2-85a6-a8f119adf6f7.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Other&nbsp;T&amp;Cs&nbsp;apply
          </a>
          .
        </p>
        <img width="480" src="/static/img/cards/register-link-cards.png" alt="Link cards" />
      </header>
    </OnboardingLayout>
  );
};

export default MobileLandingRegister;
