const mobilePattern =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? /^[8,9]{1}[0-9]{7}$/ : /^.+$/;

const validateMobile = {
  required: 'Please enter your mobile number',
  pattern: {
    value: mobilePattern,
    message: 'Please enter a valid mobile number. e.g. 91234567',
  },
  maxLength: {
    value: 8,
    message: 'Please enter a valid phone number',
  },
};

export default validateMobile;
