import { useForm } from 'react-hook-form';

import Button from '../../Button';
import { OtpFieldWithResendBtn } from '../../ResendButton';
import useResendOtpHook from '../../ResendButton/useResendOtpHook';
import OnboardingLayout from '../OnboardingLayout';

const MobileUpdate = ({ state, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { resendOtp, resendOtpHandler } = useResendOtpHook(dispatch, '/v1/verifyOnboardEmail');

  function onSubmit(values) {
    dispatch({
      type: 'UPDATE_PAYLOAD',
      value: values,
    });
    dispatch({
      type: 'PUSH_VIEW_HISTORY',
      value: MobileUpdate,
    });
    dispatch({
      type: 'UPDATE_ENDPOINT',
      value: '/v1/verifyOnboardEmailOtp',
    });
  }

  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch} />
        <h1 className="h2 onboard-title">
          You are using a new mobile number. Please update it by entering the 6-digit OTP sent to
          your registered email address.
        </h1>
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="gtm-onboarding" id="update-mobile-no">
        <fieldset disabled={state.endpoint}>
          <OtpFieldWithResendBtn
            resendOtp={resendOtp}
            resendOtpHandler={resendOtpHandler}
            errors={errors}
            register={register}
          />
          <Button block type="submit" data-testid="submit-button">
            Next
          </Button>
        </fieldset>
      </form>
    </OnboardingLayout>
  );
};

export default MobileUpdate;
