const validateEmail = {
  required: 'Please enter your email address to continue',
  pattern: {
    // eslint-disable-next-line no-useless-escape
    value: /^[\w-\+]+(\.[\w]+)*@[\w-]+(\.[\w]+)*(\.[A-Za-z]{2,70})$/,
    message: 'Please enter a valid email address to continue',
  },
};

export default validateEmail;
