import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import useReCaptcha from '../../../utils/useReCaptcha';
import validateMobile from '../../../utils/validateMobile';
import Button from '../../Button';
import { useAlert } from '../../context/alert-context';
import { TextField } from '../../Form';
import OnboardingLayout from '../OnboardingLayout';

const MobileLandingApply = ({ dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('mobileNumber', validateMobile);
  const Router = useRouter();
  const [formDisabled, setFormDisabled] = useState(false);
  const { promo } = Router.query;
  const executeReCaptcha = useReCaptcha();
  const [, alertDispatch] = useAlert();

  async function onSubmit(values) {
    delete Router.query.logout;
    try {
      // Call grecaptcha
      const res = await executeReCaptcha('MobileLandingApply', values.mobileNumber);
      if (res && res.code === '0000' && res.response.success) {
        setFormDisabled(true);
        Router.push(
          {
            pathname: '/onboarding',
            query: {
              mobileNumber: values.mobileNumber,
              flow: 'apply',
              promo,
            },
          },
          '/onboarding',
        );
      }
    } catch (err) {
      alertDispatch({
        type: 'SET_ALERT',
        value: 'Something went wrong. Please try again later.',
      });
    }
  }

  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch} />
        <h1 className="h2 onboard-title">
          Let&apos;s get started with a quick set-up of your account.
        </h1>
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="gtm-onboarding" id="mobile-apply">
        <fieldset disabled={formDisabled}>
          <TextField
            label="Mobile number"
            errors={errors}
            type="text"
            name="mobileNumber"
            {...rest}
            innerRef={ref}
          />
          <Button block type="submit">
            Next
          </Button>
        </fieldset>
      </form>
    </OnboardingLayout>
  );
};

export default MobileLandingApply;
