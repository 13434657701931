import styled from 'styled-components';

import { theme } from '../GlobalStyles';

const StyledCheckbox = styled.div`
  input[type='checkbox'] {
    display: none;
  }
  label {
    display: inline-flex;
    position: relative;
    cursor: pointer;
    padding-right: 0.75rem;
    font-size: 1rem;
  }

  label::before {
    width: 1.5rem;
    height: 1.5rem;
    border: 0.125rem solid ${theme.colors.purple};
    border-radius: 0.3125rem;
    content: '';
    margin-right: 0.75rem;
    flex-shrink: 0;
  }

  label::after {
    content: '';
    position: absolute;
    left: 0.5625rem;
    top: 0.1875rem;
    width: 0.4375rem;
    height: 0.875rem;
    border-width: 0 0.125rem 0.125rem 0;
    border-style: solid;
    border-color: #fff;
    transform: rotateZ(45deg);
    transform-origin: 50%;
    opacity: 0;
  }

  [type='checkbox']:checked + label::before {
    background: #7c22c9;
  }
  [type='checkbox']:checked + label::after {
    opacity: 1;
  }
`;

const Checkbox = (props) => {
  const { id, name, innerRef, label, onChange, defaultChecked } = props;
  return (
    <StyledCheckbox>
      <input
        defaultChecked={defaultChecked}
        ref={innerRef}
        type="checkbox"
        name={name}
        id={id}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </StyledCheckbox>
  );
};

export default Checkbox;
