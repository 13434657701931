import { useForm } from 'react-hook-form';

import useReCaptcha from '../../../utils/useReCaptcha';
import validateMobile from '../../../utils/validateMobile';
import Button from '../../Button';
import { useAlert } from '../../context/alert-context';
import { TextField } from '../../Form';
import OnboardingLayout from '../OnboardingLayout';

const SecurityMobile = ({ state, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('securityAnswer', validateMobile);
  const executeReCaptcha = useReCaptcha();
  const [, alertDispatch] = useAlert();

  async function onSubmit(values) {
    try {
      // Call grecaptcha
      const res = await executeReCaptcha('SecurityMobile', values.securityAnswer);
      if (res && res.code === '0000' && res.response.success) {
        dispatch({
          type: 'UPDATE_PAYLOAD',
          value: values,
        });
        dispatch({
          type: 'UPDATE_ENDPOINT',
          value: '/v1/verifyOnboardSecurity',
        });
      }
    } catch (err) {
      alertDispatch({
        type: 'SET_ALERT',
        value: 'Something went wrong. Please try again later.',
      });
    }
  }

  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch} />
        <h1 className="h2 onboard-title">
          Your contact details have changed. Please update it by entering your previous mobile
          number.
        </h1>
        {state.tip ? `Tip: ${state.tip}` : null}
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="gtm-onboarding" id="security-mobile">
        <fieldset disabled={state.endpoint}>
          <TextField
            label="Previous mobile number"
            errors={errors}
            type="text"
            name="securityAnswer"
            data-testid="securityAnswer"
            {...rest}
            innerRef={ref}
          />
          <Button block type="submit" data-testid="submit-button">
            Update
          </Button>
        </fieldset>
      </form>
    </OnboardingLayout>
  );
};

export default SecurityMobile;
