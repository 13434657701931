import styled from 'styled-components';

import { TextField } from '../Form';
import { theme } from '../GlobalStyles';

const ResendButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${theme.colors.purple};
  &:disabled {
    color: ${theme.colors.greyLight};
  }
`;

const OtpFieldWithResendBtn = (props) => {
  const { ref, ...rest } = props.register('otp', {
    required: 'Please enter the OTP to continue',
    pattern: {
      value: /^[0-9]{6}$/,
      message: 'Please enter the correct OTP to continue',
    },
  });
  return (
    <TextField
      label="6-digit OTP"
      alt="6-digit OTP"
      labelButton={
        <ResendButton
          type="button"
          disabled={!props.resendOtp}
          onClick={props.resendOtpHandler}
          data-testid="resend-otp"
        >
          Re-send OTP
        </ResendButton>
      }
      errors={props.errors}
      name="otp"
      type="text"
      {...rest}
      innerRef={ref}
    />
  );
};

export default ResendButton;
export { OtpFieldWithResendBtn };
