import { useEffect, useState } from 'react';

const useResendOtpHook = (dispatch, endpoint) => {
  const [resendOtp, setResendOtp] = useState(false);

  async function resendOtpHandler() {
    setResendOtp(false);
    setTimeout(() => setResendOtp(true), 30000);
    dispatch({
      type: 'UPDATE_PAYLOAD',
      value: {
        resendOTP: 1,
      },
    });
    dispatch({
      type: 'UPDATE_ENDPOINT',
      value: endpoint,
    });
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResendOtp(true);
    }, 30000); // 30 seconds

    return () => clearTimeout(timeout);
  }, []);

  return { resendOtp, resendOtpHandler };
};

export default useResendOtpHook;
