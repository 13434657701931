import styled, { css } from 'styled-components';

import theme from '../GlobalStyles/theme';

const Input = styled.input`
  ${(props) => css`
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #c6c6c6;
    border-radius: 0.5rem;
    color: #1b1b1b;
    margin-bottom: 0.25rem;
    padding: 0.9375rem;
    transition: border-color 150ms ease-in-out;
    ${
      props.error &&
      css`
        border-color: red;
      `
    }

    &:focus {
      border-color: ${theme.colors.purple};
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      color: #888;
      opacity: 0.5;
    }

    &::placeholder {
      color ${theme.colors.greyLight}
    }
  `}
`;

export default Input;
