import { useForm } from 'react-hook-form';

import validateEmail from '../../../utils/validateEmail';
import Button from '../../Button';
import { TextField } from '../../Form';
import OnboardingLayout from '../OnboardingLayout';

const EmailPrompt = ({ state, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref, ...rest } = register('email', validateEmail);

  function onSubmit(values) {
    dispatch({
      type: 'UPDATE_PAYLOAD',
      value: values,
      remove: 'resendOTP',
    });
    dispatch({
      type: 'PUSH_VIEW_HISTORY',
      value: EmailPrompt,
    });
    dispatch({
      type: 'UPDATE_ENDPOINT',
      value: '/v1/verifyOnboardEmail',
    });
  }

  return (
    <OnboardingLayout>
      <header className="onboard-header">
        <OnboardingLayout.Back dispatch={dispatch} />
        <h1 className="h2 onboard-title">
          To help us verify your account, we'll need your email address.
        </h1>
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="gtm-onboarding" id="email-prompt">
        <fieldset disabled={state.endpoint}>
          <TextField
            label="Email address"
            errors={errors}
            name="email"
            data-testid="email"
            type="email"
            {...rest}
            innerRef={ref}
          />
          <Button block type="submit" data-testid="submit-button">
            Next
          </Button>
        </fieldset>
      </form>
    </OnboardingLayout>
  );
};

export default EmailPrompt;
