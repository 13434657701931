import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { get } from './fetch';

const useReCaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return async (page, mobileNumber) => {
    try {
      const token = await executeRecaptcha(page);
      return get(`/v1/google/verifyRecaptcha?token=${token}&client=website&mobile=${mobileNumber}`);
    } catch (err) {
      throw Error(err);
    }
  };
};

export default useReCaptcha;
