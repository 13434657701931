import styled from 'styled-components';

import { theme } from '../GlobalStyles';

const Label = styled.div`
  font-size: 1rem;
  margin-bottom: 0.625rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.disabled ? '#a4a4a4' : theme.colors.black)};
`;

export default Label;
