import styled from 'styled-components';

import { theme } from '../GlobalStyles';

const StyledRadio = styled.div`
  input[type='radio'] {
    display: none;
  }
  label {
    display: inline-flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    padding-right: 0.75rem;
    font-size: 1rem;
    vertical-align: -0.25rem;
  }
  label::before {
    width: 1.5rem;
    height: 1.5rem;
    border: 0.125rem solid ${theme.colors.purple};
    background: ${theme.colors.purple};
    border-radius: 100%;
    content: '';
    margin-right: 0.75rem;
    flex-shrink: 0;
    box-shadow: 0px 0px 0 0.625rem #fff inset;
    transition: box-shadow 150ms ease-out;
  }
  input[type='radio']:checked + label::before {
    box-shadow: 0px 0px 0 0.3125rem #fff inset;
  }
`;

const Radio = (props) => {
  const { id, innerRef, label, name, defaultChecked, onChange, value } = props;
  return (
    <StyledRadio>
      <input
        name={name}
        defaultChecked={defaultChecked}
        ref={innerRef}
        type="radio"
        id={id}
        data-testid={id}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </StyledRadio>
  );
};

export default Radio;
