import styled from 'styled-components';

import { theme } from '../GlobalStyles';
import { Contain, Flex } from '../Grid';

const CustomContain = styled(Contain)`
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  ${theme.mediaQueries.above.small} {
    padding-left: 4.25rem;
    padding-right: 4.25rem;
  }
`;

const Main = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 3.75rem;
  width: 100%;
  max-width: 36.875rem;

  form {
    max-width: 24.375rem;
  }

  .onboard-header {
    margin-left: 1rem;
    margin-bottom: 2.5rem;
    position: relative;
  }

  .onboard-title {
    font-weight: 500;
    line-height: 1.3333;
  }

  ${theme.mediaQueries.above.small} {
    .onboard-header {
      margin-left: 0;
    }
  }
`;

const Back = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: 100%;
  background: transparent;
  border: none;
  width: 50px;
  height: 50px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18'%3E%3Cpath fill='%23666' fill-rule='evenodd' d='M0 9l8.4-9L10 1.714 3.2 9l6.8 7.286L8.4 18z'/%3E%3C/svg%3E")
    no-repeat center;

  ${theme.mediaQueries.above.small} {
    right: calc(100% + 1rem);
  }
`;

const Options = styled.div`
  margin: 2.5rem 0;
  &:last-child {
    margin-bottom: 0;
  }
  & > :not(:last-child) {
    margin-right: ${theme.space.lg};
  }
`;

const OnboardingLayout = ({ children }) => {
  return (
    <CustomContain>
      <Flex justifyContent="center">
        <Main>{children}</Main>
      </Flex>
    </CustomContain>
  );
};

OnboardingLayout.Back = ({ dispatch }) => (
  <Back onClick={() => dispatch({ type: 'BACK' })}>Back</Back>
);

OnboardingLayout.Options = Options;

export default OnboardingLayout;
